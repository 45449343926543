import Stack from '@mui/material/Stack';
import tokens from '@verifime/design-tokens';
import { useEffect, useState } from 'react';
import PortraitDisplay, { TPortraitDisplayProps } from './PortraitDisplay';

export type TIdentityDocumentPortraitProps = {
  fetchIdentityDocumentPortrait?: () => Promise<TPortraitDisplayProps['portraitImageUrl']>;
} & Omit<TPortraitDisplayProps, 'portraitImageUrl'>;

export default function IdentityDocumentPortrait({
  fetchIdentityDocumentPortrait,
  action,
  ...portraitProps
}: Readonly<TIdentityDocumentPortraitProps>) {
  const [portraitImageUrl, setPortraitImageUrl] = useState<
    TPortraitDisplayProps['portraitImageUrl'] | undefined
  >(undefined);

  useEffect(() => {
    fetchIdentityDocumentPortrait?.().then(setPortraitImageUrl);
  }, [fetchIdentityDocumentPortrait]);

  return (
    <Stack gap={tokens.spacingXs} padding={tokens.spacingXs}>
      <PortraitDisplay
        key={portraitImageUrl}
        portraitImageUrl={portraitImageUrl}
        action={!portraitImageUrl && action}
        {...portraitProps}
      />
    </Stack>
  );
}
