import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import tokens from '@verifime/design-tokens';
import { ReactNode, useEffect, useState } from 'react';
import DocumentNotFound from '../DocumentNotFound';

export type TPortraitDisplayProps = Partial<{
  portraitImageUrl: string;
  footer: ReactNode;
  action?: ReactNode;
}>;

export default function PortraitDisplay({
  portraitImageUrl,
  footer,
  action,
}: Readonly<TPortraitDisplayProps>) {
  const [portraitBlobUrl, setPortraitBlobUrl] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isImageBroken, setIsImageBroken] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (!portraitImageUrl) {
      setIsLoading(false);
      setIsImageBroken(true);
      return;
    }

    fetch(portraitImageUrl)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then(setPortraitBlobUrl)
      .catch(() => setIsImageBroken(true))
      .finally(() => setIsLoading(false));
  }, [portraitImageUrl]);

  return (
    <Box minWidth={tokens.size2xs} maxWidth={tokens.size2xs} minHeight={tokens.size3xs}>
      {isLoading ? (
        <CircularProgress />
      ) : isImageBroken ? (
        <DocumentNotFound text="Not Available" slot={action} />
      ) : (
        <Stack alignItems="center">
          <img
            style={{ borderRadius: tokens.borderRadiusSm }}
            alt="liveness portrait"
            src={portraitBlobUrl}
            onError={() => setIsImageBroken(true)}
            width="100%"
          />
          {footer}
          {action}
        </Stack>
      )}
    </Box>
  );
}
