import { Stack, Theme, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { ReactNode } from 'react';

export default function DocumentNotFound({
  text = 'No Image Found',
  slot,
}: {
  text?: string;
  slot?: ReactNode;
}) {
  return (
    <Stack
      width={tokens.size2xs}
      justifyContent="center"
      alignItems="center"
      gap={tokens.spacingXs}
      sx={{
        backgroundColor: (theme: Theme) => theme.palette.divider,
        borderRadius: tokens.borderRadiusSm,
        aspectRatio: '25/16',
        paddingY: tokens.spacingBase,
      }}
    >
      <Typography variant="body1" color="error">
        {text}
      </Typography>
      {slot}
    </Stack>
  );
}
